import { ChakraProvider } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import Layout from 'components/layout'
import { theme } from 'pages/app-functions'
import ErrorPage from 'pages/wrong'
import { SessionProvider } from 'next-auth/react'
import GoogleAnalytics from 'components/google-analytics'
import { useRouter } from 'next/router'
import { pageview } from 'lib/gTag'
import { useEffect } from 'react'
import HotjarTracking from 'components/hotjar'

function MyApp({ Component, pageProps, session }) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url)
    }

    //When the component is mounted, subscribe to router changes and log page views
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <ErrorBoundary fallback={(props) => <ErrorPage error={props.error} />}>
      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>
          <Layout>
            <Component {...pageProps} />

            <GoogleAnalytics
              GA_MEASUREMENT_ID={process.env.NEXT_PUBLIC_GA_ID}
            />
            <HotjarTracking hotjarId={process.env.NEXT_PUBLIC_HOTJAR_SITE_ID} />
          </Layout>
        </ChakraProvider>
      </SessionProvider>
    </ErrorBoundary>
  )
}

export default MyApp
